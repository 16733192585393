.main{
    align-items: center;
    align-content: center;
    margin-left: 2rem;
    margin-top: 1rem;
    flex-direction: row;

}
.secondDiv {
    padding: 2rem;
    margin: 1rem;
    margin: 2rem;
    margin-top: .5rem;
    margin-left: 1rem;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media (min-width: 768px) {}
}
.engineerlist{
    padding: 2rem;
    margin: 1rem;
    margin: 2.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}