.w-full {
  padding: 2rem;
  /* margin: 1rem; */
  /* margin: 2.5rem; */
  margin-top: 1rem;
  background-color: #ffffff;
  width: 500px;
  cursor: pointer;
  @media (min-width: 268px) {
  }
}

.bar {
  padding: 2rem;
  margin: 1rem;
  margin: 2.5rem;
  margin-top: 2.5rem;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (min-width: 268px) {
  }
}

.barContainer {
}

.serviceTickets {
  padding: 0.5rem;
  padding: 2.5rem;
  margin: 10rem;
  margin-left: 30rem;
  margin-top: 6rem;
  background-color: #ffffff;
  border-radius: 1.5rem;
  @media (min-width: 768px) {
  }
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 300px;
  box-shadow: 0px 0px 2px 0px hsl(210, 42%, 5%);

}

.search-bar input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  width: 300px;
}

.search-bar input:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px rgb(0, 128, 128)
  ;
}
