.forms {
    width: 90%;
    max-width: 600px;
    margin: 50px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: 40px;
  }
  
  .labelForm {
    font-weight: bold;
    width: 150px;
    margin-bottom: 10px;
    /* margin-right: 10px; */
    display: block;
  }
  
  .inputForm[type="password"],
  .inputForm[type="text"], select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
    }.inputForm[type="password"]:focus,
    .inputForm[type="text"]:focus {
        outline: 2px solid #008080;
    }
    .inputForm[type="textarea"] {
      width: 100%;
      font-size: 16px;
      border: 1px solid #ddd;
      border-radius: 5px;
      box-sizing: border-box;
      outline: none;
      margin-bottom: 10px;
    }
    
    .inputForm[type="textarea"]:focus {
      outline: 2px solid #008080;
    }
    
    
    .error {
    color: red;
    font-size: 12px;
    margin-bottom: 2px;
    display: inline-block;
    width: 100%;
    }
  
  
  .buttonForm[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: rgb(0, 128, 128);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
  }
  